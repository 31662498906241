import { createSelector } from 'reselect';
import { LegacyProductReference, TrolleyProduct } from 'constants/products';
import { BaseBuilder } from 'components/Builder/types';
import { getAddedOrderedBlendedOptimisticTrolleyItems } from '../../trolley/selectors/get-optimistic-items';
import { BundleGroupState, UnknownBundleState } from '../state';

type GroupItem = TrolleyProduct | null;

type BundleGroup = {
  items: GroupItem[];
  threshold: number;
  name: string;
};

export const getBundle = (state: WtrState): UnknownBundleState =>
  state.bundle as UnknownBundleState;

export const getBundleTitle = createSelector(getBundle, bundle => bundle.title);

const EMPTY_GROUPS: BundleGroupState[] = [];

export const getBundleGroups = createSelector(getBundle, bundle => bundle.groups ?? EMPTY_GROUPS);

export const getBundleHasError = createSelector(getBundle, bundle => !!bundle.serverError);

export const getBundleNotFound = createSelector(getBundle, bundle => !!bundle.notFound);

export const getBundleLoadingStatus = createSelector(
  getBundleTitle,
  getBundleHasError,
  getBundleNotFound,
  (title, serverError, notFound) => !title && !serverError && !notFound,
);

export const getBundleProductsGrouped = createSelector(getBundleGroups, groups => {
  const groupsMap = new Map<string, LegacyProductReference[]>();

  groups.forEach(({ products, title }) => {
    if (products.length) {
      groupsMap.set(title, products);
    }
  });

  return groupsMap;
});

export const getBundleTrolleyItemsGrouped = createSelector(
  getBundleGroups,
  getAddedOrderedBlendedOptimisticTrolleyItems,
  (groups = [], blendedTrolleyItems = []) => {
    const groupsMap = groups.map(({ products, title, thresholds }) => ({
      products,
      threshold: Object.values(thresholds)[0],
      title,
      items: [] as GroupItem[],
    }));

    blendedTrolleyItems.forEach((item: TrolleyProduct) => {
      groupsMap.forEach(group => {
        if (group.products.some(({ searchProduct }) => searchProduct === item.lineNumber)) {
          for (let i = 0; i < item?.quantity.amount; i += 1) {
            group.items.unshift(item);
          }
        }
      });
    });

    groupsMap.forEach(group => {
      for (let i = group.items.length; i < group.threshold; i += 1) {
        group.items.push(null);
      }

      if (group.items.filter(el => !!el).length >= group.threshold) {
        group.items.push(null);
      }
    });

    return groupsMap;
  },
);

const isBundleComplete = (groups: BundleGroup[] = []) =>
  groups.every(({ items, threshold }) => items.filter(el => !!el).length >= threshold);

interface BundleGroupItemsType extends BundleGroup {
  groupId: string;
}

export const getBundleBuilderItems = createSelector(getBundleTrolleyItemsGrouped, groupsMap => {
  const bundleGroups: BundleGroupItemsType[] = groupsMap.map(group => ({
    groupId: group.title,
    name: group.title,
    threshold: group.threshold,
    items: group.items,
  }));

  return [
    {
      completed: isBundleComplete(bundleGroups),
      groups: bundleGroups,
      builderId: 1,
    },
  ] as BaseBuilder[];
});
